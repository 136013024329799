import { Icon, Theme } from 'mf-ui-components'
import { Container } from 'styled-bootstrap-grid'
import styled, { css } from 'styled-components'

import { marginBreakpoints, hideBlockAt } from '../../../../utils/commonAttrs'
import { serifFont } from '../../../../utils/style/serifFont'
import { spacing } from '../../../../utils/ui/spacing'
import { textLinkStyle } from '../../../common/textLinkStyle'
import TheIcon from '../Icon'
import { Props } from './Accordion'
import { fontSize } from '../../../../utils/style/fontSize'

type WrapperProps = Pick<
  Props,
  'marginBottom' | 'hiddenAt' | 'centered' | 'variant'
>

export const Wrapper = styled.div<WrapperProps>`
  ${({ marginBottom }) => marginBreakpoints({ marginBottom }, 20)};
  ${({ hiddenAt }) => hideBlockAt({ hiddenAt })};
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  ${({ variant }) =>
    variant === 'fullWidth' &&
    css`
      border-bottom: 1px solid
        ${({ theme }: { theme: Theme }) => theme.tokens.interface.neutral.light};
      padding-top: ${spacing('md')};
      padding-bottom: ${spacing('md')};
    `};
`

export const Opener = styled.a<{
  centered?: boolean
  variant: string
  inverted: boolean
}>`
  margin: 0 auto;
  font-weight: ${({ theme }) => theme.typography.font.weight.semibold};
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ variant, inverted }) =>
    variant === 'minimal'
      ? textLinkStyle
      : css`
          font-size: ${fontSize('medium')};
          text-decoration: none;
          width: 100%;
          color: ${({ theme }) =>
            inverted
              ? theme.tokens.text.title.reversed
              : theme.tokens.text.title.regular};
          ${serifFont}
        `}

  svg {
    ${({ variant }) =>
      variant === 'minimal'
        ? `
          height: 10px;
          width: 10px;
          margin-left: 5px;
        `
        : `
          height: 24px;
          width: 24px;
          margin-left: auto;
        `}
  }
`

type MinimalToggleIconProps = { open: boolean }

export const MinimalToggleIcon = styled(Icon).attrs<MinimalToggleIconProps>(
  ({ open }) => ({
    type: open ? 'icon-chevron-up-big' : 'icon-chevron-down-big',
  })
)<MinimalToggleIconProps>`
  flex-shrink: 0;
`

export const SupportingIcon = styled(TheIcon)`
  width: 18px;
  height: 18px;
  margin-right: ${spacing('sm')};
`

export const Content = styled(Container)`
  height: 0;
  opacity: 0;
  overflow: hidden;
  text-align: left;
  margin: 0;
  transition: all 500ms cubic-bezier(0.17, 0.67, 0.54, 1.01);

  &.open {
    height: auto;
    opacity: 1;
    margin: 20px 0;
  }
`
