import React, { FC } from 'react'

import { ToggleIcon } from './FullWidthToggleIcon.styles'

type Props = {
  open?: boolean
}

export const FullWidthToggleIcon: FC<Props> = ({ open = false }) => (
  <ToggleIcon open={open}>
    <div />
    <div />
  </ToggleIcon>
)
