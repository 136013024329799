import styled, { css } from 'styled-components'

const SIZE = 32
const STROKE_WIDTH = 2

export const ToggleIcon = styled.button<{ open: boolean }>`
  width: ${SIZE}px;
  height: ${SIZE}px;
  overflow: hidden;
  border: 0;
  position: relative;
  margin-left: auto;
  background: none;
  border: none;
  flex-shrink: 0;

  > div {
    position: absolute;
    transition: transform 500ms cubic-bezier(0.17, 0.67, 0.54, 1.01);
    background: ${({ theme }) => theme.tokens.interface.neutral.strong};

    /* Vertical bar. */
    &:first-child {
      top: 0;
      bottom: 0;
      width: ${STROKE_WIDTH}px;
      left: calc(${SIZE / 2}px - ${STROKE_WIDTH / 2}px);
      height: ${SIZE / 2}px;
      margin: auto 0;
    }

    /* Horizontal bar. */
    &:last-child {
      left: 0;
      right: 0;
      height: ${STROKE_WIDTH}px;
      top: calc(${SIZE / 2}px - ${STROKE_WIDTH / 2}px);
      width: ${SIZE / 2}px;
      margin: 0 auto;
    }

    ${({ open }) =>
      open &&
      css`
        &:first-child {
          transform: rotate(90deg);
        }

        &:last-child {
          transform: rotate(180deg);
        }
      `}
  }
`
