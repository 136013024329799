import { Props } from './Accordion'

/**
 * This function sanitises the variant prop passed to the Accordion component,
 * ensuring that it is one of the allowed values.
 *
 * @param variantProp - The variant prop as passed to the Accordion component.
 * @returns The sanitised variant prop.
 */
export function sanitiseVariant(
  variantProp?: string
): NonNullable<Props['variant']> {
  switch (variantProp) {
    case 'fullWidth':
    case 'minimal':
      return variantProp

    default:
      return 'minimal'
  }
}

/**
 * This function sanitises the label prop passed to the Accordion component,
 * ensuring that it is a clean string not containing any HTML tags or special
 * characters.
 *
 * @param text - The label prop as passed to the Accordion component.
 * @returns The sanitised label prop.
 */
export function sanitiseLabel(text: string): string {
  try {
    return (
      new DOMParser().parseFromString(text, 'text/html').body.textContent ??
      text
    )
  } catch (e) {
    console.error(e)
    return text
  }
}